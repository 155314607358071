<template>
  <div class="row less-wide dashboard-content">
    <div class="col-md-6">
      <div class="row g-0 rounded-3 people-service mb-3">
        <!--                        <a href="/seeker/add-people-service">-->
        <div
          class="
            col-lg-3
            block-first
            d-md-flex
            align-items-center
            justify-content-center
            text-center
          "
        >
          <div class="icon">
            <img src="/img/dashboard/add-peo-icon-new.png" alt="Add People Service" />
          </div>
        </div>
        <div class="col-lg-9 d-flex block-second">
          <div class="d-flex flex-column flex-grow-1 justify-content-center text-white ps-4">
            <h5>People Service - Flight Companionship</h5>
            <p class="mb-0">Add your itinerary to provide air travel companionship</p>
          </div>
          <div class="d-inline-flex d-flex align-items-center flex-end h1 mb-0 me-1">
            <i class="fa fa-arrow-circle-right text-white"></i>
          </div>
        </div>
        <!--                        </a>-->
      </div>

      <div class="row g-0 rounded-3 package-service mb-3">
        <!--                        <a href="/seeker/add-package-service">-->
        <div
          class="
            col-lg-3
            block-first
            d-md-flex
            align-items-center
            justify-content-center
            text-center
          "
        >
          <div class="icon">
            <img src="/img/dashboard/add-ps-icon-new.png" alt="Add Product Service" />
          </div>
        </div>
        <div class="col-lg-9 d-flex block-second">
          <div class="d-flex flex-column flex-grow-1 justify-content-center text-white ps-4">
            <h5>Package Service - Parcel Delivery</h5>
            <p class="mb-0">Add your itinerary to deliver package at destination</p>
          </div>
          <div class="d-inline-flex d-flex align-items-center flex-end h1 mb-0 me-1">
            <i class="fa fa-arrow-circle-right text-white"></i>
          </div>
        </div>
        <!--                        </a>-->
      </div>

      <div class="row g-0 rounded-3 product-service mb-3">
        <!--                        <a href="/seeker/add-project-service">-->
        <div
          class="
            col-lg-3
            block-first
            d-md-flex
            align-items-center
            justify-content-center
            text-center
          "
        >
          <div class="icon">
            <img src="/img/dashboard/add-ps-icon-new.png" alt="Add Product Service" />
          </div>
        </div>
        <div class="col-lg-9 d-flex block-second">
          <div class="d-flex flex-column flex-grow-1 justify-content-center text-white ps-4">
            <h5>Product Service - Shopping Concierge</h5>
            <p class="mb-0">Add your itinerary to buy, carry and dispatch products</p>
          </div>
          <div class="d-inline-flex d-flex align-items-center flex-end h1 mb-0 me-1">
            <i class="fa fa-arrow-circle-right text-white"></i>
          </div>
        </div>
        <!--                        </a>-->
      </div>

      <div class="row g-0 rounded-3 search-box mb-3">
        <!--                        <a href="/seeker/add-project-service">-->
        <div
          class="
            col-lg-3
            block-first
            d-md-flex
            align-items-center
            justify-content-center
            text-center
          "
        >
          <div class="icon">
            <img src="/img/dashboard/user-search-traveller-icon.png" alt="search" />
          </div>
        </div>
        <div class="col-lg-9 d-flex block-second">
          <div class="d-flex flex-column flex-grow-1 justify-content-center text-white ps-4">
            <h5>Search seekers</h5>
            <p class="mb-0">
              Search seekers from origin to destination airports for your transportation needs
            </p>
          </div>
          <div class="d-inline-flex d-flex align-items-center flex-end h1 mb-0 me-1">
            <i class="fa fa-arrow-circle-right text-white"></i>
          </div>
        </div>
        <!--                        </a>-->
      </div>
    </div>

    <!-- middle content starts -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="middle-section-wrapper">
        <div class="card dashboard-card">
          <div class="card-header">
            <div class="icon">
              <img src="/img/dashboard/dashboard-globe-icon.png" alt="notification" />
            </div>
            <h5 class="d-inline-flex">notifications</h5>
          </div>
          <div class="card-body p-0">
            <div class="top-section">
              <div class="row no-gutter">
                <div class="col-sm-12 column">
                  <div class="left pull-left">
                    <a href="/dashboard/notifications"><h5>Total Notification</h5></a>
                  </div>
                  <div class="right pull-left">
                    <span class="count">{{ totalNotification }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom-section">
              <div class="row no-gutter">
                <div class="col-sm-4 col-xs-12 column">
                  <a href="/dashboard/notifications#traveller_inquiries">
                    <div class="count-desp">
                      <span class="count big">{{ totalTravellerInquires }}</span>
                      <span class="label">Traveller Inquires</span>
                    </div>
                  </a>
                </div>
                <div class="col-sm-4 col-xs-12 column">
                  <a href="/dashboard/notifications#send_items">
                    <div class="count-desp">
                      <span class="count big">{{ totalSeekerInquires }}</span>
                      <span class="label">Seeker Inquires</span>
                    </div>
                  </a>
                </div>
                <div class="col-sm-4 col-xs-12 column">
                  <a href="/dashboard/notifications#disputes">
                    <div class="count-desp">
                      <span class="count big">{{ totalSentItems }}</span>
                      <span class="label">Sent Items</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card dashboard-card">
          <div class="card-header">
            <div class="icon pull-left">
              <img src="/img/dashboard/dashboard-travelbag-icon.png" alt="My Travel Needs" />
            </div>
            <h5 class="pull-left">My Travel Needs</h5>
          </div>
          <div class="card-body p-0">
            <div class="top-section no-border">
              <div class="row no-gutter">
                <div class="col-sm-4 col-xs-12 column">
                  <a href="/seeker">
                    <div class="count-desp">
                      <span class="count bigger">{{ totalCurrentUpcomming }}</span>
                      <span class="label">Current &amp; Upcoming</span>
                    </div>
                  </a>
                </div>
                <div class="col-sm-4 col-xs-12 column">
                  <a href="/seeker#tab2">
                    <div class="count-desp">
                      <span class="count bigger">{{ totalCompleted }}</span>
                      <span class="label">Completed</span>
                    </div>
                  </a>
                </div>
                <div class="col-sm-4 col-xs-12 column">
                  <a href="/seeker#tab3">
                    <div class="count-desp">
                      <span class="count bigger">{{ totalCancelled }}</span>
                      <span class="label">Cancelled</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card dashboard-card">
          <div class="card-header">
            <div class="icon pull-left">
              <img src="/img/dashboard/dashboard-seeker-icon.png" alt="My Traveller Request" />
            </div>
            <h5 class="pull-left">My Traveller Requests</h5>
          </div>
          <div class="card-body p-0">
            <div class="top-section no-border">
              <div class="row no-gutter">
                <div class="col-sm-9 col-xs-6 column">
                  <div class="left pull-left">
                    <a href="/seeker/travelerrequest"><h5>TOTAL Traveller REQUEST</h5></a>
                  </div>
                  <div class="right pull-left">
                    <span class="count">{{ totalSeekerRequest }}</span>
                  </div>
                </div>
                <div class="col-sm-3 col-xs-6 column no-border">
                  <div class="more pull-left">
                    <a href="#">
                      <div class="count-desp">
                        <span class="count medium">0</span>
                        <span class="label">March 2021</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'TravellerContent',
  layout: {
    name: 'traveller',
  },
  data: function () {
    return {
      totalNotification: 0,
      totalTravellerInquires: 0,
      totalSeekerInquires: 0,
      totalSentItems: 0,
      totalCurrentUpcomming: 0,
      totalComplete: 0,
      totalCancell: 0,
      totalSeekerRequest: 0,
    };
  },
  computed: {
    ...mapGetters({
      state: 'getState',
    }),
  },
  async created() {
    let self = this;
    // console.log("state $store ", this.$store.state)
    let apiUrl = process.env.VUE_APP_API_URL;
    let token = self.$store.state.token;

    let headersObject = new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    await fetch(apiUrl + this.$store.state.currentRole + '/dashboard', {
      method: 'GET',
      headers: headersObject,
    })
      .then((response) => response.json())
      .then((resData) => {
        console.log('Success:', resData);
        // let messages = resData.messages
        if (resData.status == 'error') {
          alert('error while geting data ' + resData.message);
          return;
        }

        self.$store.commit('changeTotalNotification', resData.totalNotification);
        self.$data.totalNotification = resData.totalNotification;
        self.$data.totalCurrentUpcomming = resData.totalCurrentUpcomming;
        self.$data.totalCompleted = resData.totalCompleted;
        self.$data.totalCancelled = resData.totalCancelled;
        self.$data.totalSeekerRequest = resData.totalSeekerRequest;
      })
      .catch(function (err) {
        console.log('error', err);
      });
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/new/page/dashboard';
</style>
